@use "breakpoints";

.container {
  position: fixed;
  z-index: var(--notification-zIndex);
  left: 40px;
  bottom: 40px;

  @include breakpoints.for-tablet {
    bottom: 160px;
    left: 24px;
  }

  .notificationItemContainer {
    display: flex;
    justify-content: center;
  }

  .notification {
    width: 379px;
    padding: 9px 16px;
    background: var(--error-red);
    border-radius: 10px;

    @include breakpoints.for-tablet {
      width: calc(100vw - 48px);
    }

    .text {
      font-size: 18px;
      line-height: 22px;
      color: white;
      word-break: break-word;
    }

    &.notificationSuccess {
      background: var(--success-green);
    }

    &.notificationSocialMinted {
      background: var(--green);
    }
  }
}
