/* colors */
:root {
    --bg-color: #F7F3EA;
    --green: #073937;
    --green-10: rgba(7, 57, 55, 0.1);
    --green-hover: rgba(7, 57, 55, 0.2);
    --yellow: #E6B022;
    --border: #C9C4BD;
    --border-opacity-50: rgba(201, 196, 189, 0.5);
    --yellow-hover: rgba(201, 196, 189, 0.2);
    --yellow-hover2: #FFCD48;
    --yellow-hover3: #F1EADD;
    --yellow-hover4: rgba(201, 196, 189, 0.3);
    --blue: #0F4865;
    --rcsa-white: #FFFBF2;
    --green-white: #EAECE2;
    --error-red: #E65532;
    --success-green: #18BB77;
}

:root {
    --app-height: 100vh;
    --header-menu-zIndex: 9;
    --cart-zIndex: 99;
    --notification-zIndex: 999;
    --modal-zIndex: 9999;
}
