@use "breakpoints";

.separator {
  width: 100%;
  height: 1px;
  opacity: 0.5;
  background: var(--border);
}

.closeButton {
  border: 1px solid var(--border);
  border-radius: 50px;

  font-size: 18px;
  line-height: 22px;
  color: var(--border);

  padding: 10px 32px;
  margin: 0 auto;
}

.clippy {
  background: var(--bg-color);
  width: 108px;
  height: 108px;
  border-radius: 5px;
}

.panel {
  position: fixed;
  top: 0;
  right: 0;
  height: var(--app-height);
  z-index: var(--cart-zIndex);
}

.smallPanel {
  border-radius: 10px 0 0 10px;
  background: var(--green);
  transition: width 0.1s ease-out;

  @include breakpoints.for-tablet {
    border-radius: 0;
  }
}

.chevronButton {
  position: absolute;
  left: -39.105px;
  top: calc((100vh - 78.21px) / 2);

  background-color: var(--green);
  width: 78.21px;
  height: 78.21px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: -1;

  .chevron {
    position: absolute;
    left: 14.94px;

    img {
      transform: rotate(180deg);
      transform-origin: center;
    }

    &.expanding {
      img {
        transform: unset;
      }
    }
  }
}

.text {
  font-size: 15px;
  line-height: 21px;
  color: var(--bg-color);
}

.emptyText {
  font-size: 15px;
  line-height: 22px;
  color: var(--bg-color);
  padding: 0 36px;
}

.emptyCart {
  text-align: center;
}

.cartItem {
  padding: 21.06px 35px 24.93px;

  @include breakpoints.for-tablet {
    padding: 24px 38px;
  }
}

.cartItemTitle {
  font-size: 15px;
  line-height: 27px;
  color: var(--rcsa-white)
}

.nftContainer {
  min-width: 353px;
  min-height: 178px;
  user-select: none;

  @include breakpoints.for-tablet {
    min-width: unset;
    min-height: unset;
  }
}

.bgColorItem {
  width: 18.44px;
  height: 18.44px;
  border-radius: 50%;
}

.separator {
  height: 1px;
  background: var(--border);
  opacity: 0.5;
}

.messageToEnterAnotherUrl {
  padding: 14px 24px;
  margin: 16px 26px;
  border-radius: 10px;
  border: 1px solid var(--bg-color);
  font-size: 11px;
  line-height: 15px;
  color: white;
}

.total {
  color: white;
  font-size: 11.4111px;
  line-height: 21px;
}

.amount {
  font-size: 21.0667px;
  line-height: 38px;
  color: var(--yellow);
}

.mintButton {
  background: var(--yellow);
  padding: 8.78px 28.09px;
  border-radius: 50px;
  border: none;
  user-select: none;

  @include breakpoints.for-tablet {
    padding: 10.27px 40.07px;
  }

  &:disabled {
    opacity: 0.5;
  }

  .mintButtonText {
    font-size: 15.8px;
    line-height: 19px;
    color: var(--green);

    @include breakpoints.for-tablet {
      font-size: 13px;
      line-height: 16px;
    }
  }
}

.footer {
  padding: 16px 30px;
}

.cartItems {
  overflow-y: auto;
}

.leftResize {
  left: -8px !important;
  width: 30px !important;
}

.cartHeader {
  padding: 21px 24px 0 30px;

  .cartText {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--bg-color);
  }
}

.downloadingText {
  font-size: 12px;
  color: var(--green-white)
}

.checkedToGiftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(0,0,0,0.2);
  padding: 10.87px 22.28px;

  color: var(--yellow);
  font-size: 11.4111px;
  line-height: 16px;
  cursor: pointer;
  user-select: none;
}

.tooltip {
  width: 364px;
  font-size: 13px;
  line-height: 20px;
  background-color: var(--bg-color) !important;
  color: var(--green) !important;
  padding: 24px !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 30px rgba(0, 0, 0, 0.15);
  opacity: 1 !important;

  @include breakpoints.for-tablet {
    width: 264px;
  }
}

.gasFeeIcon {
  user-select: none;
  opacity: 0.5;
}

.guideTooltip {
  position: absolute;
  left: 35px;
  top: 89px;
  z-index: 1;
  pointer-events: none;
}

.mintedContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--yellow);
  padding: 0 55px;
}

.galleryLinkButton {
  min-width: 265px;
  min-height: 38px;
  cursor: pointer;
  user-select: none;

  .galleryLink {
    color: var(--yellow-hover2);
    font-size: 15px;
    line-height: 18px;
  }

  &:hover {
    .galleryLink {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
}
