@use "fonts";
@use "breakpoints";

.title {
  font-family: fonts.$inter;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--green);

  @include breakpoints.for-tablet {
    font-size: 18px;
    line-height: 22px;
  }
}

.inputContainer {
  border: 1px solid var(--border);
  border-radius: 50px;
  background-color: var(--bg-color);
  padding: 0 16px;
  transition: background-color 0.5s;

  &:hover {
    background-color: var(--rcsa-white);
  }

  .input {
    flex: 1;
    border: none;
    background: transparent;
    font-size: 15px;
    line-height: 18px;
    color: var(--green);
    padding: 18px 0;

    @include breakpoints.for-tablet {
      padding: 17px 0;
      font-size: 13px;
      line-height: 16px;
    }

    &::placeholder {
      color: var(--green);
    }

    &:focus {
      outline: none;
    }
  }

  &.inputFocusingContainer {
    background-color: var(--green);

    .input {
      color: var(--bg-color);

      &::placeholder {
        color: var(--bg-color);
      }
    }
  }

  .searchIcon {
    filter: invert(16%) sepia(71%) saturate(490%) hue-rotate(129deg) brightness(93%) contrast(97%);

    &.focusing {
      filter: invert(91%) sepia(3%) saturate(1773%) hue-rotate(323deg) brightness(111%) contrast(94%);
    }
  }
}

.validAddress {
  color: #18BB77;
  font-size: 13px;
  line-height: 19px;
}

.invalidAddress {
  color: #E65532;
  font-size: 13px;
  line-height: 19px;
}

.button {
  background: var(--green);
  padding: 16px;
  border: none;
  text-align: center;
  color: var(--rcsa-white);
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  cursor: pointer;
  margin: 0 -24px -24px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  @include breakpoints.for-tablet {
    font-size: 13px;
    line-height: 21px;
    border-radius: 0;
  }

  &:disabled {
    color: rgba(255,251,242, 0.2)
  }
}
