.loadingImage {
  display: flex;

  img {
    transform-origin: center;
    animation: rotation 1s infinite linear;

    @keyframes rotation {
      from {transform: rotate(0deg);}
      to {transform: rotate(359deg);}
    }
  }
}


