@use "fonts";
@use "breakpoints";

html,
body {
  padding: 0;
  margin: 0;
  font-family: fonts.$magicUI, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  background: var(--bg-color);
}

* {
  -webkit-tap-highlight-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.flex1 {
  flex: 1
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.rowDesktopColumnTablet {
  display: flex;
  flex-direction: row;

  @include breakpoints.for-tablet {
    flex-direction: column;
  }
}

.rowDesktopColumnTabletReverse {
  display: flex;
  flex-direction: row;

  @include breakpoints.for-tablet {
    flex-direction: column-reverse;
  }
}

.columnDesktopRowTablet {
  display: flex;
  flex-direction: column;

  @include breakpoints.for-tablet {
    flex-direction: row;
  }
}

.as-center {
  align-self: center;
}

.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.ai-end {
  align-items: flex-end;
}

.jc-start {
  justify-content: flex-start;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.user-select-none {
  user-select: none;
}

.pointer-events-none {
  pointer-events: none;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.relative {
  position: relative;
}

.hideOnDesktop {
  @include breakpoints.for-desktop {
    display: none !important;
  }
}

.hideOnTablet {
  @include breakpoints.for-tablet {
    display: none !important;
  }
}


.rcsaModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--modal-zIndex);

  @include breakpoints.for-tablet {
    align-items: flex-end;
  }

  .rcsaModalContent {
    display: flex;
    flex-direction: column;
    width: 518px;
    max-height: calc(var(--app-height) - 48px);
    overflow-y: auto;
    background: var(--bg-color);
    border-radius: 10px;
    padding: 24px;

    @include breakpoints.for-tablet {
      width: 100%;
      border-radius: 20px 20px 0 0;
    }
  }
}

.outlineButton {
  padding: 8px 32px;
  border: 1px solid var(--green);
  border-radius: 50px;
  color: var(--green);
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.4s ease-out;

  @include breakpoints.for-tablet {
    font-size: 16px;
    line-height: 19px;
    padding: 10px 32px;
  }

  &:hover {
    background-color: var(--green-hover);
  }
}

.outlineLightButton {
  @extend .outlineButton;
  border: 1px solid var(--border);
  color: var(--border);

  &:hover {
    background-color: var(--yellow-hover);
  }
}


.button {
  border: 1px solid var(--yellow);
  background: var(--yellow);
  border-radius: 50px;
  color: var(--green);
  font-size: 18px;
  line-height: 22px;
  padding: 10px 32px;
  user-select: none;
  cursor: pointer;
  transition: background-color 0.4s ease-out;

  &:hover {
    background: var(--yellow-hover2);
  }

  @include breakpoints.for-tablet {
    font-size: 13px;
    line-height: 16px;
    padding: 10px 24px;
  }
}

.cartShadow {
  filter: drop-shadow(0px 1px 30px rgba(0, 0, 0, 0.15));
}
