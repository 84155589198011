@use "fonts";
@use "breakpoints";

.title {
  font-family: fonts.$inter;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: var(--green);

  @include breakpoints.for-tablet {
    font-size: 18px;
    line-height: 22px;
  }
}

.agreementMessage {
  width: 100%;
  font-size: 13px;
  line-height: 21px;
  color: var(--green);
  padding: 16px;
  background: var(--border);
  border-radius: 10px;
  margin: 0;

  a {
    &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }

  @include breakpoints.for-tablet {
    padding: 19px 16px;
    font-size: 11px;
    line-height: 18px;
  }
}

.connector {
  width: 100%;
  padding: 16px;
  background: var(--border-opacity-50);
  border-radius: 10px;
  color: var(--green);
  flex-shrink: 0;

  @include breakpoints.for-tablet {
    padding: 12px 12.94px;
  }

  &:hover {
    background: var(--green);
    color: white
  }

  .connectorName {
    font-size: 16px;
    line-height: 26px;

    @include breakpoints.for-tablet {
      font-size: 13px;
      line-height: 21px;
    }
  }
}
